@import "../scss/variables";

ul.resp-tabs-list {
	margin: 0px;
	padding: 0px;
}
.resp-tabs-list li {
	font-weight: 600;
	font-size: 13px;
	display: inline-block;
	padding: 13px 15px;
	margin: 0 4px 0 0;
	list-style: none;
	cursor: pointer;
	float: left;
}
.resp-tabs-container {
	padding: 0px;
	background-color: $white;
	clear: left;
}
h2.resp-accordion {
	cursor: pointer;
	padding: 5px;
	display: none;
}
.resp-tab-content {
	display: none;
	padding: 20px 18px;
	padding-bottom: 0;
}
.resp-tab-active {
	border-bottom: none;
	margin-bottom: 0px !important;
	padding: 13px 15px !important;
	border-bottom: 0px $white solid !important;
	border-bottom: none;
	background-color: $white;
}
.resp-content-active, .resp-accordion-active {
	display: block;
}
.resp-tab-content {
	border: 1px solid #c1c1c1;
}
h2 {
	&.resp-accordion {
		font-size: 13px;
		border: 1px solid #c1c1c1;
		border-top: 0px solid #c1c1c1;
		margin: 0px;
		padding: 10px 15px;
	}
	&.resp-tab-active {
		border-bottom: 0px solid #c1c1c1 !important;
		margin-bottom: 0px !important;
		padding: 10px 15px !important;
	}
	&.resp-tab-title:last-child {
		border-bottom: 12px solid #c1c1c1 !important;
		background: blue;
	}
}
/*-----------Vertical tabs-----------*/

.resp-vtabs {
	ul.resp-tabs-list {
		float: left;
		width: 100%;
		margin-top: 0 !important;
		background: #2F3B59;
	}
	.resp-tabs-list li {
		display: block;
		margin: 0;
		cursor: pointer;
		float: none;
		text-align: center;
		background-color: transparent !important;
		border: 1px solid $white-08 !important;
		border-top: 0 !important;
		border-right: 0 !important;
		color: $white;
	}
	.resp-tabs-container {
		padding: 0px;
		background-color: $white;
		border-left: 1px solid #e9ecf3 !important;
		min-height: 100%;
		clear: none;
		padding-bottom: 20px;
	}
	.resp-tab-content {
		border: none;
		word-wrap: break-word;
		height: 100%;
	}
	li.resp-tab-active {
		position: relative;
		z-index: 1;
		text-align: center;
		border: 0 !important;
		background-color: $white-1 !important;
	}
}
.resp-arrow {
	width: 0;
	height: 0;
	float: right;
	margin-top: 3px;
	border-left: 6px solid transparent;
	border-right: 6px solid transparent;
	border-top: 12px solid #c1c1c1;
}
h2.resp-tab-active {
	span.resp-arrow {
		border: none;
		border-left: 6px solid transparent;
		border-right: 6px solid transparent;
		border-bottom: 12px solid #9B9797;
	}
	background: #DBDBDB;
	/* !important;*/
}
/*-----------Accordion styles-----------*/

.resp-easy-accordion {
	h2.resp-accordion {
		display: block;
	}
	.resp-tab-content {
		border: 1px solid #c1c1c1;
		&:last-child {
			border-bottom: 1px solid #c1c1c1;
			/* !important;*/
		}
	}
}
.resp-jfit {
	width: 100%;
	margin: 0px;
}
.resp-tab-content-active {
	display: block;
}
h2.resp-accordion:first-child {
	border-top: 1px solid #c1c1c1;
	/* !important;*/
}
@media screen and (max-device-width: 992px) and (orientation:portrait) {
    .first-sidemenu{
        position: absolute;
    }
    .sidenav-toggled .tooltip.bs-tooltip-right {
        display: none !important;
    }
} 
@media screen and (max-device-width: 992px) and (orientation:landscape) {
    .first-sidemenu{
        position: absolute;
    }
    .sidenav-toggled .tooltip.bs-tooltip-right {
        display: none !important;
    }
} 